<template lang="html" src="./pageQuotes.template.vue"></template>

<style lang="scss" src="./pageQuotes.scss"></style>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable, DataTableFilter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageQuotes.i18n');

export default {
  name: 'PageQuotes',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      quotes: [],
      loading: false,
      search: '',
      filterObject: {},
      statusFilter: [],
      typeFilter: []
    };
  },
  mounted() {
    this.filterObject = {
      status: [
        {
          name: this.$t('QuoteLocked'),
          value: 'LOCKED', 
          iconColor: 'info',
          icon: 'fas fa-unlock',
          number: 0,
          get selected() {
            return this.number > 0;
          }
        },
        {
          name: this.$t('QuoteReady'),
          value: 'READY', 
          iconColor: 'warning',
          icon: 'fas fa-clock',
          number: 0,
          get selected() {
            return this.number > 0;
          }
        },
        {
          name: this.$t('QuoteDeclined'),
          value: 'DECLINED', 
          iconColor: 'error',
          icon: 'fas fa-times-circle',
          number: 0,
        },
        {
          name: this.$t('QuoteAccepted'),
          value: 'ACCEPTED', 
          iconColor: 'success',
          icon: 'fas fa-check-circle',
          number: 0,
        }
      ],
      type: [
        {
          name: this.$t('CMT'),
          value: 'public', 
          img: 'logo_cut.png',
          number: 0,
        },
        {
          name: this.$t('PrivateWarehouse'),
          value: 'private', 
          iconColor: 'privateSupplier',
          icon: 'fas fa-warehouse',
          number: 0,
        }
      ]
    };
    this.headers = [
      {
        text: this.$t('Status'),
        align: 'center',
        value: 'status',
        width: '100px',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value) || (value === 'CLOSED' && this.statusFilter.includes('ACCEPTED'))) return true;
        }
      },
      {
        text: this.$t('Type'),
        value: 'public',
        width: '100px',
        align: 'center',
        sortable: false,
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public')) || (!value && this.typeFilter.includes('private')) ) return true;
        },
      },
      {
        text: this.$t('QuoteNumber'),
        value: 'quoteNumber',
      },
      {
        text: this.$t('Brand'),
        value: 'brand.name',
      },
      {
        text: this.$t('VATExcludedPrice'),
        value: 'dispatch.purchase.totalAmountWithoutVAT',
        align: 'right',
      },
      { text: this.$t('Created'), value: 'created', align: 'center' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  created() {
    this.getSupplierQuotes();
  },
  methods: {
    customFilter(items, search, filter) {
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    getSupplierQuotes() {
      this.quotes = [];
      this.loading = true;
      this.$apiInstance.getSupplierQuotes(
        this.$route.params.supplierUUID
      ).then(data => {
        /**
         * Reinitialize number
         */
        for(const key of Object.keys(this.filterObject)) {
          for(const filter of this.filterObject[key]) {
            filter.number = 0;
          }
        }

        this.quotes = data;
        this.quotes.forEach(quote => {
          this.filterObject.status.find(x => x.value === quote.status || (x.value === 'ACCEPTED' && quote.status === 'CLOSED')).number++;
          this.filterObject.type.find(x => (x.value === 'public' && quote.public) || (x.value === 'private' && !quote.public)).number++;
        });
      }, (err) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(err));
      }).finally(() => {
        this.loading = false;
      });
    },
    updateFilter(updatedFilter) {
      this.statusFilter = updatedFilter.status;
      if(updatedFilter.type) {
        this.typeFilter = updatedFilter.type;
      }
    },
    goToPageItem(item) {
      this.$router.push({name: 'Quote', params: { quoteUUID: `${item._id}`}});
    },
  },
};
</script>
